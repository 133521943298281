import React, { useState, useEffect, useRef } from 'react';
import { LoadingSpinner } from '@/components/LoadingSpinner';
import { ReactComponent as XClose } from '@/assets/x_close.svg';
import { InitialAvatar } from '@/components/InitialAvatar';
import { useRedirect } from '@/providers/Redirect/context';

import { useCompany } from '@/providers/ChangeCompany';

import { SEARCH_CUSTOMERS } from './queries';
import { useLazyQuery } from '@apollo/react-hooks';

import { Formik } from 'formik';

import {
  HiMagnifyingGlass,
  HiClipboardDocumentList,
  HiUsers,
  HiMiniHashtag
} from "react-icons/hi2";

import {
  DropdownContainer,
  MenuButton,
  DropdownMenu,
  StyledSearchInputContainer,
  StyledSearchInputForm,
  StyledCloseButton,
  SearchInput,
  StyledAdvanceSearchButton,
  StyledListItemNoResult,
  StyledItemList,
  StyledListItem,
  StyledBlankAvatar
} from './styled';

import { ICustomer } from '@/types/customer';
import { ISeekerCompany } from '@/types/seekerCompany';
import { Flex } from '@/components/designSystem/Layout';
import { StatusLabel } from '@/components/Label/StatusLabel';

export const SearchDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<ICustomer[]>([]);
  const [searchCustomers, { loading, error, data }] = useLazyQuery(SEARCH_CUSTOMERS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data?.searchCustomers) {
        setSearchResults(data.searchCustomers);
      } else {
        setSearchResults([]);
      }
    },
    onError: () => {
      setSearchResults([]);
    }
  });
  
  const dropdownRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const formResetRef = useRef<() => void>(() => {});

  const { toCustomerPage } = useRedirect();
  const { setCompanyId } = useCompany();
  
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        formResetRef.current();
        setQuery('');
        setSearchResults([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);
  
  const handleToggleDropdown = () => {
    if (isOpen) {
      setIsOpen(false);
      formResetRef.current();
      setQuery('');
      setSearchResults([]);
    } else {
      setIsOpen(true);
    }
  };

  const handleSearch = (term: string) => {
    console.log(term, 'term');
    setSearchResults([]);
    setQuery(term);
    searchCustomers({variables: { term }});
  }
  if (data) {
    console.log(data, 'data');
    console.log(searchResults.length)
  }

  const gotoLink = (customer: ICustomer) => {
    setCompanyId(customer.providerCompany.id);
    toCustomerPage(customer.id);
    handleToggleDropdown();
  };

  return (
    <DropdownContainer ref={dropdownRef}>
      <MenuButton onClick={handleToggleDropdown}>
        <span>Search all operating companies...</span>
        <div className="icon">
          <HiMagnifyingGlass />
        </div>
      </MenuButton>

      <DropdownMenu isOpen={isOpen}>
        <Formik
          initialValues={{ term: '' }}
          onSubmit={(values) => {
            handleSearch(values.term);
          }}
        >
          {({ values, handleSubmit, handleChange, resetForm }: any) => {
            formResetRef.current = resetForm;
            
            return (
              <StyledSearchInputContainer>
                <StyledSearchInputForm onSubmit={handleSubmit}>
                  <SearchInput
                    type="text"
                    placeholder="Search all operating companies..."
                    name="term"
                    value={values.term}
                    onChange={handleChange}
                    ref={inputRef}
                    autoFocus
                  />
                  <StyledAdvanceSearchButton type='submit'>
                    <HiMagnifyingGlass /> Search
                  </StyledAdvanceSearchButton>
                </StyledSearchInputForm>
                <StyledCloseButton onClick={handleToggleDropdown}>
                  <XClose />
                </StyledCloseButton>
              </StyledSearchInputContainer>
            )
          }}
        </Formik>

        {searchResults.length > 0 &&
          <StyledItemList>
            {data.searchCustomers.map((customer: ICustomer) => (
              <StyledListItem key={customer.id} onClick={() => gotoLink(customer)}>
                <Flex direction='column' gap='0.25rem'>
                  <Flex direction='column'>
                    <span>{customer.seekerCompany.name}</span>
                  </Flex>
                  <Flex direction='row' gap='0.5rem'>
                    {customer.latestApplication.status ? <StatusLabel value={customer.latestApplication.status} />: null}
                    {customer.isManualCustomer ? <StatusLabel value='Manual Customer' />: null}
                    {customer.providerCompany.name ? <StatusLabel value={customer.providerCompany.name} />: null}
                  </Flex>
                </Flex>

                <Flex>
                  {customer.latestApplication.assignedTo ? 
                    <InitialAvatar name={customer.latestApplication.assignedTo.name} size={2} /> :
                    <StyledBlankAvatar />
                  }
                </Flex>
              </StyledListItem>
            ))}
          </StyledItemList>
        }

        {loading && <LoadingSpinner />}

        {!loading && searchResults.length === 0 && query !== '' && (
          <StyledListItemNoResult>No results found</StyledListItemNoResult>
        )}

        {error && <StyledListItemNoResult>Error loading search results. Please try again.</StyledListItemNoResult>}
      </DropdownMenu>
    </DropdownContainer>
  );
};
