import React, { ReactNode, RefObject } from 'react';
import DataGrid, {
  RemoteOperations, Column, SearchPanel, ColumnChooser, ColumnFixing, Paging, Scrolling, HeaderFilter, StateStoring,
} from "devextreme-react/data-grid";

import { StyledDataGrid } from './styled';

interface IDashboardColumn {
  dataField: string;
  caption: string;
  allowHiding?: boolean;
  allowHeaderFiltering?: boolean;
  minWidth: string | number;
  width?: string | number;
  cellRender?: ((...params: any) => ReactNode) | undefined;
  alignment?: string;
  dataType?: string;
  visible?: boolean;
}

interface IProps {
  dataSource: any,
  columns: IDashboardColumn[],
  onRowClick: (data: any) => void,
  dataGridRef: RefObject<DataGrid<unknown, unknown>>,
  isToolbarAfterMargin?: boolean,
  toolbarRightMargin?: string,
}

export const DashboardGrid = ({ dataSource, columns, onRowClick, dataGridRef, isToolbarAfterMargin, toolbarRightMargin }: IProps) => (
  <StyledDataGrid
    ref={dataGridRef}
    dataSource={dataSource}
    showRowLines
    showColumnLines={false}
    id='dataGrid'
    keyExpr='company'
    columnMinWidth={100}
    onRowClick={onRowClick}
    isToolbarAfterMargin={isToolbarAfterMargin}
    toolbarRightMargin={toolbarRightMargin}
    noDataText=''
    isEmpty={!dataSource.length}
    remoteOperations={true}
  >
    <StateStoring enabled={true} type='localStorage' storageKey='storage' />
    <SearchPanel visible={true} width={240} placeholder='Search the table...' />
    <ColumnChooser enabled={true} mode='select' />
    <ColumnFixing enabled={true} />
    <HeaderFilter visible />
    <Scrolling mode='virtual' />
    {columns.map((column) => (
      <Column
        key={column.dataField}
        dataField={column.dataField}
        caption={column.caption}
        cellRender={column.cellRender}
        dataType={column.dataType}
        alignment={column.alignment}
        minWidth={column.minWidth}
        allowHiding={column.allowHiding}
        allowHeaderFiltering={column.allowHeaderFiltering}
        width={column.width}
        visible={column.visible}
      />
    ))}
  </StyledDataGrid>
);
